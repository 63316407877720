import styled from 'styled-components';

export const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  h2 {
    font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
      sans-serif;
    position: relative;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    font-style: italic;
    padding: 8px 16px 8px 8px;
    margin-right: 8px;
    background-color: #664e70;
    color: white;
    display: flex;
    align-items: center;
    &::before {
      content: '/';
      margin-right: 8px;
      font-size: 22px;
      line-height: 20px;
    }
    &::after {
      width: 0;
      height: 0;
      border-bottom: 36px solid #fff;
      border-left: 14px solid #664e70;
      content: ' ';
      position: absolute;
      right: 0px;
      top: 0;
      margin-top: 0;
      display: block;
    }
  }
  .close-icon {
    border: none;
    background-color: #275f7c;
    position: relative;
    color: white;
    padding: 6px;
    outline: none;
    &:hover {
      cursor: pointer;
    }
    &::after {
      width: 0;
      height: 0;
      border-bottom: 36px solid #275f7c;
      border-left: 14px solid #fff;
      content: ' ';
      position: absolute;
      right: 100%;
      top: 0;
      margin-top: 0;
      display: block;
    }
    svg {
      font-size: 24px;
    }
  }
`;

export const MainContent = styled.div`
  padding: 24px;
  .input-wrapper {
    position: relative;
    input {
      outline: none;
      font-family: 'HelveticaLTStd-roman', 'Helvetica Neue', Helvetica,
        sans-serif;
      height: 48px;
      font-size: 14px;
      line-height: 18px;
      color: #1b384d;
      padding: 15px 30px 15px 15px;
      width: 100%;
      display: block;
      border: 1px solid #5b892b;
      &::placeholder {
        color: #1b384d !important;
        opacity: 1;
      }
    }
    .close-icon {
      &:hover {
        cursor: pointer;
      }
      font-size: 18px;
      color: #1b384d;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

export const FiltersContainer = styled.div`
  margin-top: 24px;
  overflow: auto;
  height: 336px;
  scrollbar-width: normal;
  scrollbar-color: #ccc transparent;
  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 6px;
  }
  hr {
    display: none;
  }
  .check-box-container-parent {
    > div {
      padding-bottom: 12px;
      &:last-child {
        padding-bottom: 0;
      }
      > .filter-align {
        font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica,
          sans-serif;
        color: #1b384d;
        font-size: 16px;
        line-height: 20px;
        padding-bottom: 4px;
        .filter-btn {
          pointer-events: none;
          img {
            display: none;
          }
        }
        label {
          display: none;
        }
      }
      .collapse {
        .check-box-container-children {
          column-count: 3;
          column-fill: balance;
          @media screen and (max-width: 767px) {
            column-count: 2;
          }
          column-gap: 16px;
        }
      }
    }
  }

  .check-box-container-children {
    .filter-align {
      display: inline-block;
      position: relative;
      width: 100%;
    }

    .filter-btn {
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;
      h3 {
        white-space: pre-wrap;
        order: 1;
        width: 100%;
        word-break: break-word;
        font-size: 14px;
        line-height: 18px;
        padding: 4px 0;
        font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
          sans-serif;
        padding-left: 34px;
        color: #555555;
        &.title {
          padding-left: 0;
          font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica,
            sans-serif;
        }
      }
      img {
        align-self: flex-start;
        position: relative;
        top: 8px;
        padding: 0 7px;
        order: 2;
      }
    }
    label {
      display: flex;
      input {
        position: absolute;
        top: 5px;
        left: 4px;
        opacity: 0;
      }
      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 3px;
        left: 2px;
        height: 16px;
        width: 16px;
        background: url(/images/checkbox-empty.png) no-repeat center center;
        cursor: pointer;
      }
      input:checked ~ .checkmark {
        background: url(/images/checkbox-fill.png) no-repeat center center;
      }
    }
  }
`;

export const Actions = styled.div`
  padding: 0 24px 24px;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 767px) {
    justify-content: space-between;
  }
  .clear-button {
    margin-right: 24px;
    text-transform: uppercase;
    text-align: left;
    white-space: nowrap;
    color: #5b892b;
    font-size: 14px;
    line-height: 18px;
    background-color: transparent;
    border: none;
    position: relative;
    padding: 0 0 0 24px;
    &:hover {
      text-decoration: underline;
    }
    &::before {
      position: absolute;
      background: url(/images/two-line-green.png) center center no-repeat;
      width: 18px;
      height: 22px;
      content: '';
      left: 4px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:disabled {
      opacity: 0.3;
      cursor: default;
      text-decoration: none;
    }
    @media screen and (max-width: 767px) {
      margin-right: 0;
    }
  }
  .apply-btn {
    text-align: left;
    height: 100%;
    padding: 9px 63px 9px 15px;
    position: relative;
    height: 36px;
    word-break: break-word;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #00617f;
    border: solid 1px #00617f;
    color: white;
    font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
      sans-serif;
    @media screen and (max-width: 767px) {
      margin-bottom: 16px;
    }
    @media screen and (min-width: 360px) and (max-width: 767px) {
      margin-bottom: 0;
    }

    span {
      display: flex;
      position: absolute;
      right: -1px;
      top: -1px;
      width: 48px;
      height: calc(100% + 2px);
      justify-content: center;
      align-items: center;
      background-color: #10384f;
      svg {
        width: 24px;
        height: 24px;
      }
      ::before {
        height: 100%;
        width: 14px;
        background: linear-gradient(
          to right bottom,
          transparent 49%,
          #10384f 50%
        );
        content: ' ';
        position: absolute;
        left: -14px;
        top: 0;
      }
    }
    &:hover {
      background-color: #0069d9;
      border: solid 1px #0069d9;
    }
  }
`;
