import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { StaticQuery, graphql } from 'gatsby';
import { Row, Col, Collapse, Label } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import { sortData } from './search-service';
import CheckboxGroup from './checkbox/checkbox-group';
import { Advanced } from './advanced-search.css';
import {
  IoIosArrowUp,
  IoIosArrowDown,
  IoIosArrowForward,
} from 'react-icons/io';
import { FaFilter, FaTimes } from 'react-icons/fa';
import FindTrial from '../search/find-a-trial';
import ModalConditions from './modal-condition/modal-conditions';
import { MasterDataContext } from '../../store/masterDataContext';

const AdvancedSearch = ({
  location,
  data,
  searchCallBack,
  studyIds,
  pageContext,
}) => {
  const { masterData } = useContext(MasterDataContext);
  const selectedFiltersCount = useRef({});
  const [selectedFilters, setSelectedFilter] = useState({});
  const [filterData, setFilterData] = useState({});
  const [searchParams, setSearchParams] = useState(null);
  const [previousValues, setPreviousValues] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [areNewResults, setAreNewResults] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  const [isFilterSectionOpened, setIsFilterSectionOpened] = useState(false);
  const [
    isActiveFilterSectionVisible,
    setIsActiveFilterSectionVisible,
  ] = useState(false);
  const { width } = windowSize;
  const masterDataValueParser = {
    RECSTATUS: 'Status',
    AGERANGE: 'ageRange',
    CONDITION: 'conditions',
    GENDER: 'gender',
    HEALTHY_VOL: 'healthyVol',
    PHASE: 'phases',
    ATTACHTYPE: 'studyResult',
    STUDY_TYPE: 'studyType',
    BIOMARKERS: 'biomarkers',
    IS_FLEXIBLE_TRIAL: 'IsFlexibleTrial',
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const hideModal = () => {
    setIsModalOpen(false);
  };

  const getSelectedFiltersCount = selFilters => {
    let count = 0;
    const internalSelectedFilters = selFilters || selectedFilters;
    if (internalSelectedFilters) {
      Object.keys(internalSelectedFilters).forEach(key => {
        if (internalSelectedFilters[key].length > 0) {
          if (typeof internalSelectedFilters[key] === 'string') {
            count += 1;
          } else {
            count += internalSelectedFilters[key].length;
          }
        }
      });
    }
    return count;
  };

  const updateSelectedFilters = searchParams => {
    const values = {
      SearchTerm: '',
      Status: [],
      ageRange: [],
      conditions: [],
      gender: [],
      healthyVol: [],
      phases: [],
      studyResult: [],
      studyType: [],
      biomarkers: [],
      IsFlexibleTrial: [],
    };
    const filterData = sortData(data.advancedEnJson, masterData || {});
    Object.keys(masterDataValueParser).forEach(key => {
      if (searchParams.get(masterDataValueParser[key])) {
        const filters = searchParams.get(masterDataValueParser[key]).split('~');
        filters.forEach(filter => {
          const item =
            filterData[key] &&
            filterData[key].find(val => val.InternalValue === filter);
          if (!item && filterData[key]) {
            filterData[key].forEach(val => {
              if (val && val.Children && val.Children.length > 0) {
                const childItem = val.Children.find(
                  val => val.InternalValue === filter
                );
                if (childItem) {
                  values[masterDataValueParser[key]].push(childItem);
                }
              }
            });
          }

          if (item) {
            values[masterDataValueParser[key]].push(item);
          }
        });
      }
    });
    setSelectedFilter(values);
    selectedFiltersCount.current = getSelectedFiltersCount(values);
  };

  useEffect(() => {
    setIsLoading(true);
    let searchParams = null;
    Object.assign(masterData || {}, {
      IS_FLEXIBLE_TRIAL: [
        {
          InternalValue: 'true',
          DisplayValue: 'Yes',
          Children: null,
          SortOrder: 0,
          Synonyms: null,
        },
      ],
    });

    try {
      searchParams = new URL(location.href).searchParams;
    } catch (e) {
      searchParams = new URLSearchParams('');
    }

    setSearchParams(searchParams);

    // Get the sorted data need for rendering checkboxes using search service
    let filterData = sortData(data.advancedEnJson, masterData || {});

    setFilterData(filterData);
    updateSelectedFilters(searchParams);

    setIsLoading(false);
  }, [location, masterData]);

  const handleSubmit = values => {
    let searchParams = null;
    try {
      searchParams = new URL(window ? window.location.href : location.href)
        .searchParams;
    } catch (e) {
      searchParams = new URLSearchParams('');
    }

    let finalQuery = {};

    searchParams.forEach((value, key) => {
      finalQuery[key] = value;
    });
    Object.keys(values).forEach(key => {
      finalQuery[key] = values[key];
    });

    finalQuery.page = '0';
    finalQuery.SortField = 'Location_Distance';
    finalQuery.SortOrder = 'asc';
    let newSearchQuery = Object.keys(finalQuery)
      .map(
        k =>
          encodeURIComponent(k) +
          '=' +
          encodeURIComponent(
            Array.isArray(finalQuery[k])
              ? finalQuery[k].join('~')
              : `${finalQuery[k]}`
          )
      )
      .join('&');

    if (newSearchQuery && `?${newSearchQuery}` !== window.location.search) {
      navigate(`/studies/?${newSearchQuery}`, { replace: true });
      if (searchCallBack && typeof searchCallBack === 'function') {
        setTimeout(() => {
          searchCallBack();
        });
      }
    }
  };

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (width < 992 && isFilterSectionOpened) {
      document.body.setAttribute('style', 'overflow:hidden');
    } else {
      document.body.setAttribute('style', 'overflow:auto');
    }
  }, [isFilterSectionOpened, width]);

  const renderFilter = setFieldValue => {
    const filtersLayout = [
      ['CONDITION'],
      ['RECSTATUS', 'PHASE', 'IS_FLEXIBLE_TRIAL'],
      ['GENDER', 'AGERANGE', 'BIOMARKERS'],
      ['ATTACHTYPE', 'HEALTHY_VOL', 'STUDY_TYPE'],
    ];
    return filtersLayout.map((filters, index) => (
      <Col key={index} lg={3} className="filter-col">
        <Row>
          {filters.map((filter, index) => {
            const group = JSON.parse(JSON.stringify(filterData[filter] || {}));
            if (group && group.length > 0) {
              if (
                filter === 'CONDITION' &&
                selectedFilters['conditions'] &&
                selectedFilters['conditions'].length > 0
              ) {
                const sortedSelectedCondition = selectedFilters['conditions']
                  .map(val => val)
                  .sort((a, b) => {
                    if (a.DisplayValue > b.DisplayValue) {
                      return -1;
                    }
                    if (a.DisplayValue < b.DisplayValue) {
                      return 1;
                    }
                    return 0;
                  });
                group.map(val => {
                  sortedSelectedCondition.map(sorted => {
                    if (
                      val &&
                      val.Children &&
                      val.Children.length > 0 &&
                      val.Children.find(
                        child => child.InternalValue === sorted.InternalValue
                      )
                    ) {
                      const foundedElement = val.Children.find(
                        child => child.InternalValue === sorted.InternalValue
                      );
                      val.Children.unshift(
                        val.Children.splice(
                          val.Children.indexOf(foundedElement),
                          1
                        )[0]
                      );
                    }
                  });
                });
              }
              return (
                <CheckboxGroup
                  isTitleToggleable={width && width < 992}
                  key={index}
                  group={group}
                  groupName={
                    data.advancedEnJson.filterItems.find(
                      val => val.masterDataName == filter
                    ).formikInitialValue
                  }
                  groupLabel={
                    data.advancedEnJson.filterItems.find(
                      val => val.masterDataName == filter
                    ).label
                  }
                  positionInList={index}
                  isSimple={false}
                />
              );
            }
            return null;
          })}
          {index === 0 ? (
            <Col xs={12}>
              <ModalConditions
                isModalOpen={isModalOpen}
                showModal={showModal}
                hideModal={hideModal}
                conditions={filterData['CONDITION'] || []}
                selectedFilters={selectedFilters['conditions'] || ''}
                onSubmit={values => {
                  setFieldValue('conditions', values);
                }}
              />
            </Col>
          ) : null}
        </Row>
      </Col>
    ));
  };

  const getValues = (paramName, defaultValue) => {
    return (
      (searchParams &&
        searchParams.get(paramName) &&
        searchParams.get(paramName).split('~')) ||
      defaultValue
    );
  };

  const onSelectedFilterChange = (field, form, InternalValue) => {
    let nextValue = [];
    if (field.value && field.value.includes(InternalValue)) {
      nextValue = field.value.filter(oldVal => oldVal !== InternalValue);
      const newFilter = selectedFilters[field.name].filter(
        val => InternalValue !== val.InternalValue
      );
      setSelectedFilter({
        ...selectedFilters,
        [field.name]: newFilter,
      });
      form.setFieldValue(field.name, nextValue);
    }
  };

  useEffect(() => {
    if (selectedFiltersCount.current && selectedFiltersCount.current > 0) {
      setIsActiveFilterSectionVisible(true);
    }
  }, [selectedFiltersCount.current]);

  useEffect(() => {
    if (areNewResults) {
      setTimeout(() => {
        setAreNewResults(false);
      }, 3000);
    }
  }, [areNewResults]);
  return (
    <Advanced
      className={`${width < 992 && isFilterSectionOpened ? 'fixed' : ''}`}
    >
      {(!isFilterSectionOpened && width < 992) || width >= 992 ? (
        <FindTrial
          onResults={true}
          navigate={navigate}
          location={location}
          pageContext={pageContext}
          searchCallBack={searchCallBack}
          studyIds={studyIds}
        />
      ) : null}
      {areNewResults ? (
        <div className="new-results">{'New Search Results Available'}</div>
      ) : null}
      {searchParams && !isLoading && (
        <Formik
          enableReinitialize={false}
          initialValues={{
            conditions: getValues('conditions', []),
            phases: getValues('phases', []),
            gender: getValues('gender', []),
            healthyVol: getValues('healthyVol', []),
            ageRange: getValues('ageRange', []),
            Status: getValues('Status', []),
            studyResult: getValues('studyResult', []),
            studyType: getValues('studyType', []),
            UniqueIdentifiers: getValues('UniqueIdentifiers', []),
            EudraCTIds: getValues('EudraCTIds', []),
            biomarkers: getValues('biomarkers', []),
            IsFlexibleTrial: getValues('IsFlexibleTrial', []),
          }}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            handleSubmit(values);
            actions.setSubmitting(false);
          }}
          render={({
            values,
            initialValues,
            setFieldValue,
            handleSubmit: formikHandleSumit,
          }) => {
            if (values && initialValues) {
              if (
                JSON.stringify(values) !== JSON.stringify(previousValues) &&
                values !== initialValues &&
                !isModalOpen
              ) {
                setTimeout(() => {
                  setPreviousValues(values);
                  handleSubmit(values);
                  setAreNewResults(true);
                });
              }
            }
            return (
              <Form
                onKeyDown={e => {
                  var key = e.charCode || e.keyCode || 0;
                  if (key == 13) {
                    e.preventDefault();
                    formikHandleSumit(values);
                  }
                }}
              >
                <Row>
                  <Col sm={12}>
                    <button
                      type="button"
                      style={{ width: '100%' }}
                      onClick={() => {
                        if (selectedFiltersCount.current > 0) {
                          setIsFilterSectionOpened(!isFilterSectionOpened);
                        } else if (
                          isActiveFilterSectionVisible &&
                          isFilterSectionOpened
                        ) {
                          setIsActiveFilterSectionVisible(false);
                          setIsFilterSectionOpened(false);
                        } else if (!isActiveFilterSectionVisible) {
                          setIsActiveFilterSectionVisible(true);
                          if (selectedFiltersCount.current === 0) {
                            setIsFilterSectionOpened(true);
                          }
                        } else if (isActiveFilterSectionVisible) {
                          setIsFilterSectionOpened(true);
                        }
                      }}
                      className={`filter-header ${
                        isFilterSectionOpened ? 'open' : ''
                      }`}
                    >
                      <div className="title-wrapper">
                        <FaFilter />
                        <h3>
                          {data.advancedEnJson.filter}{' '}
                          {selectedFiltersCount.current > 0
                            ? ` - ${selectedFiltersCount.current} Filters Applied`
                            : null}
                        </h3>
                      </div>
                      <div className="icon-wrapper">
                        {isFilterSectionOpened ? (
                          <IoIosArrowUp />
                        ) : (
                          <IoIosArrowDown />
                        )}
                      </div>
                    </button>
                  </Col>
                  <Collapse
                    isOpen={
                      (isActiveFilterSectionVisible &&
                        selectedFiltersCount.current > 0 &&
                        width < 992 &&
                        !isFilterSectionOpened) ||
                      (isActiveFilterSectionVisible &&
                        selectedFiltersCount.current > 0 &&
                        width >= 992)
                    }
                    className="selected-filters-collapse"
                  >
                    <Col xs={12} className="selected-filters-container">
                      <div className="selected-filters">
                        {selectedFilters
                          ? Object.keys(selectedFilters).map(
                              (key, keyIndex) => {
                                if (selectedFilters[key].length > 0) {
                                  if (Array.isArray(selectedFilters[key])) {
                                    return selectedFilters[key].map(
                                      (filter, index) => (
                                        <div
                                          className="selected-filter-wrapper"
                                          key={index}
                                        >
                                          <Field name={key}>
                                            {({ field, form }) => {
                                              return (
                                                <Label
                                                  htmlFor={filter.InternalValue}
                                                  onClick={e => {
                                                    e.preventDefault();
                                                  }}
                                                >
                                                  {filter.DisplayValue}
                                                  <input
                                                    type="checkbox"
                                                    checked={
                                                      field.value &&
                                                      field.value.includes(
                                                        filter.InternalValue
                                                      )
                                                    }
                                                    onChange={() => {
                                                      onSelectedFilterChange(
                                                        field,
                                                        form,
                                                        filter.InternalValue
                                                      );
                                                    }}
                                                    id={filter.InternalValue}
                                                  />
                                                </Label>
                                              );
                                            }}
                                          </Field>
                                          <FaTimes className="close-icon" />
                                        </div>
                                      )
                                    );
                                  }
                                  if (
                                    typeof selectedFilters[key] === 'string'
                                  ) {
                                    return (
                                      <div
                                        key={keyIndex}
                                        className="selected-filter-wrapper"
                                      >
                                        <Field name={key}>
                                          {({ field }) => {
                                            return (
                                              <Label
                                                htmlFor={selectedFilters[key]}
                                                onClick={e => {
                                                  e.preventDefault();
                                                }}
                                              >
                                                {selectedFilters[key]}
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    field.value &&
                                                    field.value.includes(
                                                      selectedFilters[key]
                                                    )
                                                  }
                                                  onChange={() => {
                                                    setSelectedFilter({
                                                      ...selectedFilters,
                                                      [key]: '',
                                                    });
                                                    setFieldValue(key, '');
                                                  }}
                                                  id={selectedFilters[key]}
                                                />
                                              </Label>
                                            );
                                          }}
                                        </Field>
                                        <FaTimes className="close-icon" />
                                      </div>
                                    );
                                  }
                                }
                              }
                            )
                          : null}
                      </div>
                      <div className="actions-container">
                        <button
                          className={'apply-btn'}
                          type="submit"
                          onClick={() => {
                            setIsFilterSectionOpened(false);
                          }}
                        >
                          {data.advancedEnJson.apply}
                          <span>
                            <IoIosArrowForward />
                          </span>
                        </button>
                        <button
                          className="clear-button"
                          onClick={() => {
                            navigate('/studies') &&
                              window &&
                              window.location.reload();
                          }}
                          disabled={getSelectedFiltersCount() === 0}
                        >
                          {data.advancedEnJson.clear}
                        </button>
                      </div>
                    </Col>
                  </Collapse>
                  <Collapse
                    isOpen={
                      isActiveFilterSectionVisible && isFilterSectionOpened
                    }
                    className="collapse-wrapper"
                  >
                    <Col xs={12} className="filters-container">
                      <Row>{renderFilter(setFieldValue)}</Row>
                      <div className="actions-container">
                        <button
                          className={'apply-btn'}
                          type="submit"
                          onClick={() => {
                            setIsFilterSectionOpened(false);
                          }}
                        >
                          {data.advancedEnJson.apply}
                          <span>
                            <IoIosArrowForward />
                          </span>
                        </button>
                        <button
                          className="clear-button"
                          onClick={() => {
                            navigate('/studies') &&
                              window &&
                              window.location.reload();
                          }}
                          disabled={getSelectedFiltersCount() === 0}
                        >
                          {data.advancedEnJson.clear}
                        </button>
                      </div>
                    </Col>
                  </Collapse>
                </Row>
              </Form>
            );
          }}
        />
      )}
    </Advanced>
  );
};

AdvancedSearch.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
  searchCallBack: PropTypes.func,
  studyIds: PropTypes.array,
};

const AdvancedSearchQuery = props => (
  <StaticQuery
    query={graphql`
      query AdvancedSearch {
        trialSearchEnJson {
          keywordLabel
          keywordPH
          keyowrdTooltip
        }
        advancedEnJson {
          apply
          filter
          clear
          filterItems {
            masterDataName
            formikInitialValue
            label
          }
        }
      }
    `}
    render={data => <AdvancedSearch data={data} {...props} />}
  />
);

AdvancedSearchQuery.propTypes = {
  children: PropTypes.node,
};

export default AdvancedSearchQuery;
