import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useConnectInfo from '../../helpers/useConnectInfo';
import { CustomSpinner, SpinnerWrapper } from '../layout/layout.css';

const PreloaderConnectInfo = ({ studyIds, children, hasSpinner = true }) => {
  const { isLoading, loadInfo } = useConnectInfo(studyIds);

  useEffect(() => {
    loadInfo();
  }, [studyIds]);

  return isLoading && hasSpinner ? (
    <SpinnerWrapper>
      <CustomSpinner />
    </SpinnerWrapper>
  ) : (
    <>{children}</>
  );
};

PreloaderConnectInfo.propTypes = {
  studyIds: PropTypes.array.isRequired,
  children: PropTypes.any,
  hasSpinner: PropTypes.any,
};
export default PreloaderConnectInfo;
