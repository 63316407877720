import styled from 'styled-components';

export const Advanced = styled.div`
  margin-bottom: 30px;
  .new-results {
    position: fixed;
    z-index: 2;
    bottom: 50px;
    background-color: #343742;
    left: 50%;
    color: white;
    font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica, sans-serif;
    transform: translateX(-50%);
    white-space: nowrap;
    padding: 16px 24px;
    font-size: 16px;
    line-height: 20px;
  }

  .actions-container {
    display: flex;
    justify-content: end;
    margin-top: 32px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    @media screen and (min-width: 360px) and (max-width: 767px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
  .clear-button {
    text-transform: uppercase;
    text-align: left;
    white-space: nowrap;
    color: #5b892b;
    font-size: 14px;
    line-height: 18px;
    background-color: transparent;
    border: none;
    position: relative;
    padding: 0 0 0 24px;
    @media screen and (max-width: 767px) {
      margin-bottom: 16px;
    }
    @media screen and (min-width: 360px) and (max-width: 767px) {
      margin-bottom: 0;
    }
    &:hover {
      text-decoration: underline;
    }
    &::before {
      position: absolute;
      background: url(/images/two-line-green.png) center center no-repeat;
      width: 18px;
      height: 22px;
      content: '';
      left: 4px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:disabled {
      opacity: 0.3;
      cursor: default;
      text-decoration: none;
    }
  }
  .apply-btn {
    margin-right: 24px;
    text-align: left;
    height: 100%;
    padding: 9px 63px 9px 15px;
    position: relative;
    height: 36px;
    word-break: break-word;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #00617f;
    border: solid 1px #00617f;
    color: white;
    font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
      sans-serif;
    @media screen and (max-width: 767px) {
      margin-right: 0;
    }
    span {
      display: flex;
      position: absolute;
      right: -1px;
      top: -1px;
      width: 48px;
      height: calc(100% + 2px);
      justify-content: center;
      align-items: center;
      background-color: #10384f;
      svg {
        width: 24px;
        height: 24px;
      }
      ::before {
        height: 100%;
        width: 14px;
        background: linear-gradient(
          to right bottom,
          transparent 49%,
          #10384f 50%
        );
        content: ' ';
        position: absolute;
        left: -14px;
        top: 0;
      }
    }
    &:hover {
      background-color: #0069d9;
      border: solid 1px #0069d9;
    }
  }
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
  }
  form {
    background-color: white;
    .row {
      div:last-child {
        hr {
          display: none;
        }
      }
    }
  }
  .filter-header,
  .filter-header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #00617f;
    &:hover,
    &.open {
      background-color: #2d6ad2;
    }
    height: 48px;
    position: relative;
    border: none;
    padding: 0;
    outline: none;
    h3 {
      font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-weight: 400;
      color: #ffffff;
      padding: 12px 15px 12px 4px;
      font-size: 14px;
      line-height: 18px;
      cursor: pointer;
      margin-top: 2px;
    }
    .title-wrapper {
      display: flex;
      align-items: center;
      padding-left: 16px;
      svg {
        font-size: 24px;
        color: white;
      }
    }
    .icon-wrapper {
      line-height: 48px;
      width: 48px;
      font-size: 24px;
      font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica,
        sans-serif;
      background-color: #10384f;
      color: #ffffff;
      border: none;
      position: relative;

      ::before {
        position: absolute;
        left: -10px;
        top: 0;
        bottom: 0;
        content: ' ';
        border-top: 48px solid transparent;
        border-right: 10px solid #10384f;
      }
    }
  }
  .check-box-container-parent::-webkit-scrollbar {
    width: 14px;
  }

  .check-box-container-parent::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 6px;
  }

  .check-box-container-parent::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 6px;
  }
  .check-box-container-parent {
    scrollbar-width: normal;
    scrollbar-color: #ccc transparent;
    max-height: 280px;
    overflow-y: auto;

    @media screen and (min-width: 992px) {
      margin-left: 30px;
      margin-left: 0px;
      max-height: 375px;
    }
    .filter-btn h3 {
      line-height: 30px;
    }
    .fixed-height-container {
      max-height: 270px;
      overflow-y: auto;
    }
  }
  .check-box-container-children {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  .filter-align {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
  }
  .filter-btn {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    h3 {
      order: 1;
      &.RECSTATUS_RECRUITING,
      &.ATTACHTYPE_LAY_LANGUAGE_SUMMARY {
        color: #3d90d9;
        font-weight: 700;
      }
      width: 100%;
      word-break: break-word;
      font-size: 14px;
      line-height: 32px;
      font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
        sans-serif;
      padding-left: 34px;
      color: #555555;
      @media screen and (min-width: 992px) {
        font-size: 14px;
        line-height: 18px;
      }
      &.title {
        padding-left: 0;
        font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica,
          sans-serif;
      }
    }
    img {
      align-self: flex-start;
      position: relative;
      top: 8px;
      padding: 0 7px;
      order: 2;
    }
  }
  label {
    display: flex;
    input {
      position: absolute;
      top: 7px;
      left: 4px;
      opacity: 0;
    }
    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 5px;
      left: 2px;
      height: 16px;
      width: 16px;
      background: url(/images/checkbox-empty.png) no-repeat center center;
      cursor: pointer;
    }
    input:checked ~ .checkmark {
      background: url(/images/checkbox-fill.png) no-repeat center center;
    }
  }
  hr {
    margin-top: 5px;
    margin-bottom: 5px;
    border-top: 1px solid #333;
    margin-left: 5px;
    margin-right: 5px;
  }
  @media screen and (max-width: 767px) {
    .filter-header-mobile {
      justify-content: flex-end;
      h3 {
        margin: 0;
        width: 100%;
      }
      img {
        display: none;
      }
      button {
        padding: 0 15px;
      }
      .reset-btn-mobile {
        width: 100%;
        text-align: right;
        background-color: transparent;
        margin-right: 10px;
        ::before {
          display: none;
        }
      }
    }
  }
  .selected-filters-collapse {
    width: 100%;
  }
  .selected-filters-container {
    display: flex;
    justify-content: space-between;
    padding: 16px 32px 8px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      .clear-button {
        margin-top: 16px;
        align-self: end;
      }
    }
    .actions-container {
      align-items: flex-start;
      margin: 0;
      button {
        height: 36px;
      }
      .apply-btn {
        white-space: nowrap;
      }
      .clear-button {
        margin-top: 0;
      }
      @media screen and (max-width: 450px) {
        flex-direction: column;
        align-items: flex-end;
        .clear-button {
          margin-top: 16px;
        }
      }
    }
    .selected-filters {
      display: flex;
      flex-wrap: wrap;
      .selected-filter-wrapper {
        margin: 0 8px 8px 0;
        position: relative;
        input,
        .close-icon {
          cursor: pointer;
          top: 50%;
          transform: translateY(-50%);
          right: 6px;
          position: absolute;
          margin-top: -1px;
          left: initial;
        }
        .close-icon {
          color: white;
          font-size: 11px;
        }
        label {
          font-size: 14px;
          line-height: 18px;
          position: relative;
          padding-right: 20px;
          padding: 3px 32px 3px 6px;
          background: #664e70;
          color: white;
          text-align: left;
          input {
            z-index: 999;
          }

          .checkmark {
            display: none;
          }
        }
      }
    }
  }
  .filters-container {
    padding: 16px 32px 32px;
    .filter-col {
      .filters-group {
        padding-bottom: 26px;
        .filter-btn {
          h3 {
            &.title {
              color: #1b384d;
            }
          }
        }
        @media screen and (min-width: 992px) {
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
  .collapse-wrapper {
    width: 100%;
  }
  .keyword-wrapper {
    padding-top: 16px;
    h3 {
      font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      padding: 16px 15px 10px;
      color: #10384f;
      white-space: nowrap;
      span {
        position: relative;
        left: 5px;
        top: -5px;
      }
    }
    .keyword-close-icon {
      bottom: 18px;
      right: 33px;
    }
    input {
      &::-ms-clear {
        display: none;
      }
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-appearance: none;
      width: 100%;
      background-color: #ffffff;
      border: 1px solid #488c0f;
      border-radius: 0;
      font-family: 'HelveticaLTStd-roman', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 14px;
      color: #10384f;
      text-align: left;
      position: relative;
      line-height: 21px;
      height: 48px;
      padding: 15px 30px 15px 15px;
    }
    .suggestions {
      max-width: calc(100% - 30px);
      width: 100%;
      border-left: 1px solid #488c0f;
      border-right: 1px solid #488c0f;
      border-bottom: 1px solid #488c0f;
      border-top: 0;
      padding: 0;
      margin: 0;
      border-radius: 0;
      max-height: 270px;
      overflow: hidden;
      position: absolute;
      z-index: 10;
      background-color: #ffffff;
      margin-top: -2px;
      .dropdown-item,
      li {
        list-style-type: none;
        font-family: 'HelveticaLTStd-roman', 'Helvetica Neue', Helvetica,
          sans-serif;
        font-size: 14px;
        line-height: 1.5;
        border-top: 1px solid #488c0f;
        font-weight: 400;
        background-color: #ffffff;
        padding: 6px 14px;
        color: #10384f;
        text-align: left;
        :hover {
          background-color: #e4e4e4;
        }
        &.no-match {
          pointer-events: none;
          font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
            sans-serif;
          font-weight: normal;
          span {
            font-style: italic;
          }
        }
      }
      @media screen and (max-width: 767px) {
        .dropdown-item,
        li {
          font-size: 16px !important;
        }
      }
    }
  }
`;
