import styled from 'styled-components';

export const PopupContainer = styled.div`
  padding: 30px 20px;
  .align-fields {
    display: flex;
    align-items: center;
    min-height: 40px;
    margin-bottom: 10px;
    > *:nth-child(4) {
      display: block;
    }
    > *:nth-child(3) {
      text-align: center;
      h4 {
        text-align: center;
        display: none;
      }
    }
    .dropdown {
      padding-right: 30px;
    }
  }
  .align-fields:nth-child(2) {
    > *:nth-child(3) {
      h4 {
        display: block;
      }
    }
  }
  .close-button {
    background: #00617f;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 999;
    opacity: 1;
    border-radius: 50%;
    border: none;
    svg {
      color: #ffffff;
      font-size: 20px;
    }
    :focus {
      outline: none;
    }
  }
  h3 {
    font-size: 24px;
    color: #10384f;
    font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
      sans-serif;
    text-align: left;
    line-height: 33px;
    margin-bottom: 20px;
  }
  h4 {
    font-size: 16px;
    color: #10384f;
    font-family: 'HelveticaLTStd-Bold', 'Helvetica Neue', Helvetica, sans-serif;
    text-align: left;
    line-height: 22px;
  }
  p {
    font-size: 14px;
    color: #10384f;
    font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
      sans-serif;
    text-align: left;
    line-height: 17px;
  }
  .radio-btn-container {
    &.mobile {
      display: none;
    }
    text-align: center;
    label {
      width: fit-content;
      margin: 0 auto;
      position: relative;
    }
    input {
      opacity: 0;
    }
    /* Create a custom radiobtn */
    .radiomark {
      position: absolute;
      top: -6px;
      left: -6px;
      height: 24px;
      width: 24px;
      background-color: #ffffff;
      border-radius: 50%;
      border: 1px solid #cccccc;
    }

    input:active ~ .radiomark {
      background-color: #ffffff;
    }
    input:checked ~ .radiomark {
      background-color: #ffffff;
      border: 1px solid #00627f;
    }
    input:disabled ~ .radiomark {
      opacity: 0.3;
    }
    .radiomark:after {
      content: '';
      position: absolute;
      display: none;
      top: 2px;
      left: 2px;
      background: #00627f;
      width: 18px;
      height: 18px;
      border-radius: 50%;
    }
    input:checked ~ .radiomark:after {
      display: block;
    }
  }
  .select-btn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-appearance: none;
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #488c0f;
    border-radius: 0;
    font-family: 'HelveticaLTStd-roman', 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #10384f;
    padding: 11px;
    padding-right: 55px;
    text-align: left;
    position: relative;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    span {
      display: flex;
      justify-content: flex-start;
      position: absolute;
      right: 0;
      top: -1px;
      width: 44px;
      height: 44px;
      background-color: #4a8c0f;
      color: #ffffff;
      align-items: center;
      svg {
        margin-left: 5px;
        width: 26px;
        height: 26px;
      }
      ::before {
        width: 0;
        height: 0;
        border-bottom: 48px solid #4a8c0f;
        border-left: 14px solid transparent;
        content: ' ';
        position: absolute;
        left: -14px;
        top: 0;
        margin-top: 0;
        display: block;
      }
    }
    :focus {
      box-shadow: 0 0 2px #76cd19;
      border-color: #76cd19;
      border-bottom-color: #4a8c0f;
      outline: 0;
    }
    &::placeholder {
      color: #10384f !important;
      opacity: 1;
    }
    :disabled {
      opacity: 0.4;
    }
    &.active {
      span {
        display: none;
      }
    }
  }
  .select-btn {
    &.active {
      span {
        display: none;
      }
    }
  }
  .dropdown-menu {
    width: calc(100% - 30px);
    border: 1px solid #488c0f;
    border-top: none;
    border-top: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    max-height: 170px;
    overflow: auto;
    position: absolute;
    z-index: 10;
    top: 2px !important;
    background-color: #ffffff;
    margin-top: -2px;
    .dropdown-item {
      list-style-type: none;
      font-family: 'HelveticaLTStd-roman', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 14px;
      line-height: 1.5;
      border-top: 1px solid #488c0f;
      font-weight: 400;
      background-color: #ffffff;
      padding: 6px 14px;
      color: #10384f;
      text-align: left;
      :hover {
        background-color: #e4e4e4;
      }
    }
    .dropdown-item:first-child {
      border-top: none;
    }
  }
  .download-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    button,
    a {
      display: flex;
      align-items: center;
      border-radius: 0;
      background-color: #00617f;
      border: solid 1px #00617f;
      font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      height: 48px;
      padding-right: 72px;
      position: relative;
      padding-left: 15px;
      color: #ffffff;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      span {
        display: flex;
        position: absolute;
        right: 0;
        top: 0;
        width: 46px;
        height: 46px;
        background-color: #10384f;
        justify-content: center;
        align-items: center;
        svg {
          width: 18px;
          height: 18px;
        }
        ::before {
          width: 0;
          height: 0;
          border-bottom: 46px solid #10384f;
          border-left: 14px solid transparent;
          content: ' ';
          position: absolute;
          left: -14px;
          top: 0;
          margin-top: 0;
          display: block;
        }
      }
      :hover {
        background-color: #0069d9;
        border: solid 1px #0069d9;
        text-decoration: none;
      }
      :focus {
        outline: none;
      }
    }
    a[disabled] {
      cursor: default;
      opacity: 0.5;
      :hover {
        pointer-events: none;
        background-color: #00617f;
        border: solid 1px #00617f;
      }
    }
  }
  .thanks-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #e5e5e5;
      margin: 60px 0 30px 0;
      svg {
        font-size: 63px;
        color: #488c0f;
      }
    }
    h4,
    p {
      font-size: 24px;
      color: #10384f;
      font-family: 'Helvetica-LT-Std-roman', 'Helvetica Neue', Helvetica,
        sans-serif;
      text-align: left;
      line-height: 33px;
      margin-bottom: 30px;
    }
    p {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 15px;
    }

    button {
      display: flex;
      align-items: center;
      border-radius: 0;
      background-color: #66b512;
      border: solid 1px #66b512;
      font-family: 'HelveticaLTStd-BoldObl', 'Helvetica Neue', Helvetica,
        sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      height: 48px;
      padding-left: 72px;
      position: relative;
      padding-right: 15px;
      color: #ffffff;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      span {
        display: flex;
        position: absolute;
        left: 0;
        top: 0;
        width: 46px;
        height: 46px;
        background-color: #488c0f;
        justify-content: center;
        align-items: center;
        svg {
          width: 18px;
          height: 18px;
        }
        ::before {
          width: 0;
          height: 0;
          border-top: 46px solid #488c0f;
          border-right: 14px solid transparent;
          content: ' ';
          position: absolute;
          right: -14px;
          top: 0;
          margin-top: 0;
          display: block;
        }
      }
      :hover {
        background-color: #76cd19;
        border: solid 1px #76cd19;
        text-decoration: none;
      }
      :focus {
        outline: none;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .align-fields {
      .dropdown {
        padding-right: 20px;
      }
      .dropdown-menu {
        width: calc(100% - 20px);
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 30px 15px;
    .align-fields {
      .dropdown {
        padding-right: 0;
      }
      .dropdown-menu {
        width: 100%;
      }
      > *:nth-child(3) {
        h4 {
          display: block;
          text-align: left;
          font-size: 12px;
          line-height: 17px;
          margin-bottom: 10px;
          margin-top: 22px;
        }
      }
      > *:nth-child(4) {
        display: none;
      }
    }
    .align-fields:nth-child(2) {
      > *:nth-child(2) {
        display: none;
      }
      > *:first-child {
        h4 {
          margin-bottom: 22px;
        }
      }
      > *:last-child {
        h4 {
          text-align: center;
          margin-top: 0;
          margin-bottom: 22px;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
    h3 {
      font-size: 18px;
      line-height: 24px;
    }
    .radio-btn-container {
      &.mobile {
        display: block;
      }
    }
    .download-btn {
      a {
        padding: 0;
        width: 100%;
        justify-content: center;
      }
    }
  }
  @media screen and (max-width: 767px) and (orientation: landscape) {
    padding: 15px;
    .thanks-container {
      > div:first-child {
        margin-top: 20px;
      }
    }
  }
  @media screen and (max-width: 374px) {
    .download-btn {
      a {
        padding-right: 72px;
      }
    }
    .thanks-container {
      h4,
      p {
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 991px) and (orientation: landscape) {
    .dropdown-menu {
      transform: translate3d(0px, 40px, 0px) !important;
      max-height: 100px;
    }
  }
  @media screen and (max-width: 480px) {
    .mobile-select {
      text-align: end !important;
    }
  }
`;
