/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getCurrentLangId } from '../../constants/config';
import {
  FaEnvelope,
  FaLinkedinIn,
  FaPrint,
  FaTwitter,
  FaFacebookF,
} from 'react-icons/fa';
import { ShareCss } from './share.css';
import { StyledTooltip } from '../../global.css';

const ShareSection = ({ data }) => {
  const [isChineese, setIsChineese] = useState(false);
  useEffect(() => {
    setIsChineese(getCurrentLangId() === 'cn' ? true : false);
  }, []);

  return (
    <ShareCss>
      <a
        target="_blank"
        rel="noopener noreferrer"
        style={{
          background: '#3b5998',
        }}
        className="facebook"
        href={`https://www.facebook.com/sharer/sharer.php?u=${
          typeof window !== 'undefined' &&
          window.location &&
          window.location.href
            ? encodeURIComponent(window.location.href)
            : ''
        }`}
      >
        <FaFacebookF title="Facebook" alt="Facebook" />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="twitter"
        href={`https://twitter.com/share?url=${
          typeof window !== 'undefined' &&
          window.location &&
          window.location.href
            ? encodeURIComponent(window.location.href)
            : ''
        }`}
      >
        <FaTwitter title="Twitter" alt="Twitter" />
      </a>
      <a
        className="linkedIn"
        rel="noopener noreferrer"
        target="_blank"
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${
          typeof window !== 'undefined' &&
          window.location &&
          window.location.href
            ? encodeURIComponent(window.location.href)
            : ''
        }`}
      >
        <FaLinkedinIn title="LinkedIn" alt="LinkedIn" />
      </a>
      <a
        className="email"
        href={`mailto:?subject=${data.dataTitle}&body=${data.dataDescription}${
          data.learnMore ? `${encodeURIComponent('\r\n')}${data.learnMore}` : ''
        } ${
          typeof window !== 'undefined' &&
          window.location &&
          window.location.href
            ? encodeURIComponent(' \r\n' + window.location.href)
            : ''
        }`}
      >
        <FaEnvelope />
      </a>
      <button
        aria-label="Print Page"
        id="print"
        className="print"
        onClick={() => {
          window.print();
        }}
      >
        <FaPrint />
      </button>
      {isChineese ? (
        <div>
          <span id="wechat-button" className="wechat">
            <img
              src="/images/icon-wechat.png"
              alt="wechat-icon"
              width="18px"
              height="18px"
            />
          </span>
          <StyledTooltip
            trigger="hover"
            placement="bottom"
            target="wechat-button"
            className="align-print-tooltip"
          >
            <img
              src="/images/wechat-qr-code.png"
              alt="wechat-qr"
              width="150px"
              height="150px"
            />
          </StyledTooltip>
        </div>
      ) : null}
    </ShareCss>
  );
};

ShareSection.propTypes = {
  data: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default ShareSection;
