import React from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';

const ResultsPagination = ({
  simple = false,
  activePage,
  onChange,
  itemsCountPerPage,
  totalItemsCount,
}) => {
  if (simple) {
    let numberOfPages = Math.ceil(totalItemsCount / itemsCountPerPage);

    return (
      <div className="simple-pagination">
        {activePage > 1 && (
          <button
            onClick={() => onChange && onChange(activePage - 1)}
            className="pagination-arrow left"
            type="button"
          >
            <span className="fa fa-chevron-left" />
          </button>
        )}
        {activePage < numberOfPages && (
          <button
            onClick={() => onChange && onChange(activePage + 1)}
            className="pagination-arrow"
            type="button"
          >
            <span className="fa fa-chevron-right" />
          </button>
        )}
      </div>
    );
  } else {
    return (
      <Pagination
        activePage={activePage}
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        nextPageText="»"
        prevPageText="«"
        hideFirstLastPages={true}
        hideDisabled={true}
        pageRangeDisplayed={4}
        itemClass="page-item"
        linkClass="page-link"
        linkClassNext="text-link"
        linkClassPrev="text-link"
        activeLinkClass="active"
        onChange={onChange}
      />
    );
  }
};

ResultsPagination.propTypes = {
  simple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  activePage: PropTypes.number.isRequired,
  itemsCountPerPage: PropTypes.number.isRequired,
  totalItemsCount: PropTypes.number.isRequired,
};

export default ResultsPagination;
