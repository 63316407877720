/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Row, Col, Collapse } from 'reactstrap';
import { navigate } from '@reach/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import axios from 'axios';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import {
  allTrialsIds,
  connectApiToken,
  connectApiUrl,
  searchTrialUrl,
} from '../constants/config';
import Layout from 'components/layout';
import Pagination from '../components/search/pagination';
import Advanced from '../components/search/advanced-search';
import ShareSection from '../components/share-buttons';
import {
  Fonts,
  Container,
  ResultsPage,
  ResultsTable,
  StyledTooltip,
} from '../global.css';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import {
  trackAnalytics,
  filterSearchEventProperties,
} from '../helpers/trackAnalytics';
import { ConnectDataContext } from '../helpers/useConnectInfo';
import PreloaderConnectInfo from '../components/connect/PreloaderConnectInfo';
import { CustomSpinner, SpinnerWrapper } from '../components/layout/layout.css';
import TableRow from '../components/studies/table-row';
import { MasterDataContext } from '../store/masterDataContext';

class Results extends React.Component {
  static contextType = MasterDataContext;

  constructor(props) {
    if (props.data && props.data.searchResultsEnJson) {
      props.data.trialsContent = props.data.searchResultsEnJson;
    }
    super(props);

    this.state = {
      query: '',
      searchResults: [],
      totalResults: null,
      searchParams: null,
      showFilter: false,
      modalOpen: [],
      resetPageAndFilters: false,
      urlParams: '',
      info: [],
    };
    this.isMobile = false;
    this.goToPage = this.goToPage.bind(this);
    this.showFilter = this.showFilter.bind(this);
  }

  getSearchPayload() {
    let { location } = this.props,
      searchParams = null;
    try {
      searchParams = new URL(window ? window.location.href : location.href)
        .searchParams;
    } catch (e) {
      searchParams = new URLSearchParams('');
    }
    let params = {
      PageSize: 10,
      page: '0',
      SortField: 'Location_Distance',
      SortOrder: 'asc',
      Keyword: '',
      SearchTerm: '',
      Status: '',
      Longitude: '',
      Latitude: '',
      ageRange: '',
      conditions: '',
      phases: '',
      gender: '',
      healthyVol: '',
      studyType: '',
      studyResult: '',
      MileRadius: 100,
      locationCountryInternal: '',
      biomarkers: '',
      IsFlexibleTrial: '',
    };

    Object.keys(params).forEach(key => {
      if (searchParams.get(key)) {
        params[key] = searchParams.get(key);
      }
    });

    if (params.page.length) {
      params.PageIndex = parseInt(params.page, 10);
    }
    if (params.conditions.length) {
      params.Conditions = params.conditions;
    }
    if (params.studyType.length) {
      params.StudyTypes = params.studyType;
    }
    if (params.studyResult.length) {
      params.AttachmentTypes = params.studyResult;
    }
    if (params.healthyVol.length) {
      params.HealthyVolunteer = params.healthyVol;
    }
    if (params.ageRange.length) {
      params.AgeRanges = params.ageRange;
    }
    if (params.gender.length) {
      params.Gender = params.gender;
    }
    if (params.Keyword.length) {
      params.SearchTerm = decodeURI(
        window.OneLinkPrs ? window.OneLinkPrs(params.Keyword) : params.Keyword
      );
    }

    if (this.state.resetPageAndFilters) {
      params.page = 0;
      params.SortField = 'Location_Distance';
      params.SortOrder = 'asc';
    }

    return params;
  }

  showFilter() {
    this.setState({
      showFilter: !this.state.showFilter,
    });
  }
  componentDidMount() {
    this.makeSearchRequest(this.getSearchPayload());

    if (typeof window !== 'undefined') {
      this.isMobile = window.innerWidth && window.innerWidth < 768;
    }
  }

  componentWillUnmount() {
    if (this.markers && this.markers.length && this.cluster) {
      this.markers.forEach(marker => {
        this.cluster.removeMarker(marker);
      });
    }
  }

  setInfo(value) {
    this.setState({ info: value });
  }

  removeRedundantParams(searchParams) {
    delete searchParams.studyResult;
    return searchParams;
  }

  makeSearchRequest(searchParams) {
    searchParams = this.removeRedundantParams(searchParams);
    this.setState({ searchParams });
    NProgress.configure({ showSpinner: false }).start();
    const eventParams = filterSearchEventProperties(searchParams);

    axios
      .post(searchTrialUrl(), searchParams)
      .then(res => {
        let searchResults = res.data.Data.map(row => {
          return {
            ...row,
            toggleFlag: false,
          };
        });
        if (!searchParams.SearchTerm && res.data.Success) {
          this.setState({ searchResults, totalResults: res.data.Count });
          NProgress.done();
        } else {
          axios
            .get(allTrialsIds())
            .then(ids => {
              NProgress.done();
              let totalResults = res.data.Count;
              const itemFound = ids.data.Data.find(
                val =>
                  val.UniqueIdentifier === searchParams.SearchTerm ||
                  val.NCTID === searchParams.SearchTerm
              );
              if (itemFound) {
                searchResults = searchResults.filter(val => val.IsExactMatch);
                totalResults = 1;
              }
              if (res.data.Success) {
                this.setState({
                  searchResults,
                  totalResults,
                });
              }
            })
            .catch(() => {
              NProgress.done();
            });
        }

        trackAnalytics('SearchResponse', {
          Count: res.data.Count,
          Success: res.data.Success,
          Context: res.data.Context,
          ...eventParams,
        });

        if (res.data.Success) {
          let modalOpen = res.data.Data.map(() => false);

          this.setState({
            modalOpen,
          });
          if (
            typeof window !== 'undefined' &&
            window.addthis &&
            window.addthis.update &&
            window.location &&
            window.location.href
          ) {
            window.addthis.update('share', 'url', `${window.location.href}`);
            window.addthis.update(
              'share',
              'title',
              `${this.props.data.searchResultsEnJson.addThisWidgetParams.dataTitle}`
            );
            window.addthis.update('share', 'imp_url', 1);
          }
        }
      })
      .catch(error => {
        trackAnalytics('SearchResponseFailure', {
          Error: error.message,
          Count: -1,
          Success: false,
          Context: '',
          ...eventParams,
        });
      });

    let urlSearchParams = null;
    try {
      urlSearchParams = `${
        new URL(window ? window.location.href : location.href).searchParams
      }`;
    } catch (e) {
      urlSearchParams = new URLSearchParams('');
    }
    this.setState({ urlParams: urlSearchParams });
  }

  refreshSearchParams(values) {
    let { location } = this.props,
      searchParams = null;
    try {
      searchParams = new URL(window ? window.location.href : location.href)
        .searchParams;
    } catch (e) {
      searchParams = new URLSearchParams('');
    }

    let finalQuery = {};
    searchParams.forEach((value, key) => {
      finalQuery[key] = value;
    });
    Object.keys(values).forEach(key => {
      if (
        ![
          'PageIndex',
          'MileRadius',
          'PageSize',
          'AttachmentTypes',
          'StudyTypes',
          'HealthyVolunteer',
          'Countries',
          'SearchTerm',
          'AgeRanges',
        ].includes(key)
      ) {
        finalQuery[key] = values[key];
      }
    });

    let newSearchQuery = Object.keys(finalQuery)
      .map(
        k =>
          encodeURIComponent(k) +
          '=' +
          encodeURIComponent(
            Array.isArray(finalQuery[k])
              ? finalQuery[k].join('~')
              : `${finalQuery[k]}`
          )
      )
      .join('&');

    if (
      newSearchQuery &&
      typeof window !== 'undefined' &&
      `?${newSearchQuery}` !== window.location.search
    ) {
      navigate(`/studies/?${newSearchQuery}`, { replace: false });
    }
  }

  buildCanonicalURL() {
    if (typeof window !== 'undefined' && this.state.searchParams !== null) {
      let { searchParams } = this.state;
      let url = Object.keys(searchParams)
        .map(
          k =>
            encodeURIComponent(k) +
            '=' +
            encodeURIComponent(
              Array.isArray(searchParams[k])
                ? searchParams[k].join('~')
                : `${searchParams[k]}`
            )
        )
        .join('&');
      return window.location.origin + window.location.pathname + '?' + `${url}`;
    }
  }

  orderByField(fieldName, direction) {
    let { searchParams } = this.state;
    searchParams.SortField = fieldName;
    searchParams.SortOrder = direction;
    this.refreshSearchParams(searchParams);
    this.makeSearchRequest(searchParams);
    trackAnalytics('SearchOrder', {
      Field: fieldName,
      Direction: direction,
    });
  }

  goToPage(pageNumber) {
    let { searchParams } = this.state;
    if (pageNumber - 1 !== this.state.searchParams.PageIndex) {
      searchParams.page = pageNumber - 1;
      searchParams.PageIndex = pageNumber - 1;
      this.refreshSearchParams(searchParams);
      this.makeSearchRequest(searchParams);
      this.setState({ info: [] });
    }
  }

  showModal(index) {
    let { modalOpen } = this.state;
    modalOpen[index] = true;
    this.setState({
      modalOpen: modalOpen,
    });
  }

  hideModal(index) {
    let { modalOpen } = this.state;
    modalOpen[index] = false;
    this.setState({
      modalOpen: modalOpen,
    });
  }

  buildDisplayFilters(searchParams, useAllParams) {
    let { data } = this.props;

    let displayData = [];
    let neededParams = [
      'conditions',
      'Status',
      'phases',
      'gender',
      'ageRange',
      'biomarkers',
      'studyResult',
    ];

    const masterData =
      this.context && this.context.masterData ? this.context.masterData : {};

    neededParams.forEach(neededParam => {
      Object.keys(searchParams).forEach(param => {
        if (param === neededParam) {
          if (`${searchParams[param]}`.length === 0) {
            displayData.push(
              `All ${data.searchResultsEnJson.noResultsParams[0][param]}`
            );
          } else {
            let values = `${searchParams[param]}`.split('~');
            let displaySec = [];
            values.forEach(internalVal => {
              Object.values(masterData).forEach(dataSection => {
                if (Array.isArray(dataSection)) {
                  dataSection.forEach(item => {
                    if (item.InternalValue === internalVal) {
                      displaySec.push(item.DisplayValue);
                    }
                    if (item.Children && Array.isArray(item.Children)) {
                      item.Children.forEach(subItem => {
                        if (subItem.InternalValue === internalVal) {
                          displaySec.push(subItem.DisplayValue);
                        }
                      });
                    }
                  });
                }
              });
            });
            displaySec.push(data.searchResultsEnJson.noResultsParams[0][param]);
            displayData.push(displaySec.join(' '));
          }
        }
      });
    });

    if (useAllParams) {
      if (searchParams.Keyword && searchParams.Keyword.length) {
        displayData.push(
          `Keyword: "${decodeURI(searchParams.Keyword).trim()}"`
        );
      }

      if (
        searchParams.healthyVol &&
        searchParams.healthyVol.length &&
        masterData &&
        Array.isArray(masterData.HEALTHY_VOL)
      ) {
        masterData.HEALTHY_VOL.forEach(child => {
          if (child.InternalValue === searchParams.healthyVol) {
            displayData.push(
              `Accepting Healthy Volunteers: ${child.DisplayValue}`
            );
          }
        });
      }

      if (
        searchParams.studyType &&
        searchParams.studyType.length &&
        masterData &&
        Array.isArray(masterData.STUDY_TYPE)
      ) {
        masterData.STUDY_TYPE.forEach(type => {
          if (type.InternalValue === searchParams.studyType) {
            displayData.push(`Study Type: ${type.DisplayValue}`);
          }
        });
      }

      if (
        searchParams.locationCountryInternal &&
        searchParams.locationCountryInternal.length &&
        masterData &&
        Array.isArray(masterData.COUNTRY)
      ) {
        masterData.COUNTRY.forEach(country => {
          if (country.InternalValue === searchParams.locationCountryInternal) {
            displayData.push(`Location: ${country.DisplayValue}`);
          }
        });
      }

      if (searchParams.pcode) {
        displayData.push(searchParams.pcode);
      }
    }
    return displayData.join(', ');
  }

  filteringCondition = conditions => {
    let uniqCondition = [];
    return conditions.filter(cond => {
      if (!uniqCondition.includes(cond.DisplayValue)) {
        uniqCondition.push(cond.DisplayValue);
        return true;
      }
      return false;
    });
  };

  filteringRownCondition = conditions => {
    let uniqCondition = [];
    return conditions.filter(cond => {
      if (!uniqCondition.includes(cond.LookupValue)) {
        uniqCondition.push(cond.LookupValue);
        return true;
      }
      return false;
    });
  };

  generateSearchParamForStudyDetails = (row, isRecruiting) => {
    const studyDetailsPath = `${row.UniqueIdentifier}${
      this.state.searchParams.Latitude.length &&
      this.state.searchParams.Latitude.length
        ? `/?searchLat=${row.Location_Latitude}&searchLong=${
            row.Location_Longitude
          }&name=${
            row.Location_Name !== null ? row.Location_Name : ''
          }&baseLat=${this.state.searchParams.Latitude}&baseLong=${
            this.state.searchParams.Longitude
          }`
        : ''
    }`;

    return isRecruiting
      ? studyDetailsPath.concat('#where-to-participate')
      : studyDetailsPath.concat('/');
  };

  renderResultsTable(searchResults) {
    let { data } = this.props;
    if (!this.state.totalResults) {
      return undefined;
    }
    return (
      <PreloaderConnectInfo
        studyIds={searchResults.filter(val => val.NCTID).map(val => val.NCTID)}
      >
        <Table className="results-table">
          {data.searchResultsEnJson.tableHeader.map((headerItem, index) => (
            <Thead key={index}>
              <Tr>
                <Th className="table-head-1">{headerItem.conditions}</Th>
                <Th className="table-head-2">{headerItem.medicalCondition}</Th>
                <Th
                  className="table-head-3"
                  onClick={() =>
                    this.orderByField(
                      'StatusDisplay',
                      this.state.searchParams.SortField === 'StatusDisplay'
                        ? this.state.searchParams.SortOrder === 'asc'
                          ? 'desc'
                          : 'asc'
                        : 'asc'
                    )
                  }
                >
                  {headerItem.status}
                  {this.state.searchParams.SortField === 'StatusDisplay' ? (
                    this.state.searchParams.SortOrder === 'asc' ? (
                      <FaArrowUp />
                    ) : (
                      <FaArrowDown />
                    )
                  ) : (
                    <FaArrowDown />
                  )}
                </Th>
                <Th className="table-head-4">{headerItem.requirements}</Th>
                <Th className="table-head-5">{headerItem.distance}</Th>
                <Th>&nbsp;</Th>
              </Tr>
            </Thead>
          ))}

          <Tbody className={'table-body'}>
            {searchResults.map((row, index) => (
              <TableRow
                key={index}
                data={this.props.data}
                index={index}
                row={row}
                searchParams={this.state.searchParams}
              />
            ))}
            {this.state.totalResults &&
            this.state.totalResults > this.state.searchParams.PageSize ? (
              <Tr className="pagination-row">
                <Td colSpan={6}>
                  <Pagination
                    activePage={this.state.searchParams.PageIndex + 1}
                    itemsCountPerPage={this.state.searchParams.PageSize}
                    totalItemsCount={this.state.totalResults}
                    onChange={this.goToPage}
                    simple={this.isMobile}
                  />
                </Td>
              </Tr>
            ) : null}
          </Tbody>
        </Table>
      </PreloaderConnectInfo>
    );
  }

  render() {
    let { data } = this.props;
    let displayFilters = this.buildDisplayFilters(
      this.getSearchPayload(),
      true
    );
    let canonical = this.buildCanonicalURL();
    return (
      <ConnectDataContext.Provider
        value={{
          info: this.state.info,
          setInfo: this.setInfo.bind(this),
          connectApiUrl: connectApiUrl(),
          connectApiToken: connectApiToken(),
        }}
      >
        <div>
          <Layout
            meta={{
              pageTitle: data.searchResultsEnJson.seo.pageTitle,
              pageDescription: `${
                displayFilters.length
                  ? `${data.searchResultsEnJson.seo.pageDescriptionWithFilters}: `
                  : data.searchResultsEnJson.seo.pageDescriptionWithoutFilters
              }${displayFilters}`,
              canonical: canonical,
              imageUrl: `${data.site.siteMetadata.siteUrl}/images/clinical_trials_explorer_logo.png`,
            }}
          >
            <Fonts>
              <ResultsPage>
                <Container className={this.state.showFilter ? 'hide-page' : ''}>
                  <Row>
                    <Col xs={12}>
                      <div>
                        <div className="results-header">
                          <h3>{data.searchResultsEnJson.title}</h3>
                          <div
                            className={'filter-mobile'}
                            onClick={this.showFilter}
                          >
                            <img src={'/images/filter.png'} alt="filter-icon" />
                            <p>{data.searchResultsEnJson.filter}</p>
                          </div>
                          <div className="share-section desktop">
                            <p>{data.searchResultsEnJson.share}</p>
                            <ShareSection
                              data={
                                data.searchResultsEnJson.addThisWidgetParams
                              }
                            />
                            <StyledTooltip
                              trigger="hover"
                              placement="top"
                              target="print"
                              className="align-print-tooltip"
                            >
                              {data.searchResultsEnJson.printTooltip}
                            </StyledTooltip>
                          </div>
                        </div>

                        <div className="share-section mobile">
                          <p>{data.searchResultsEnJson.share}</p>
                          <ShareSection
                            data={data.searchResultsEnJson.addThisWidgetParams}
                          />
                          <StyledTooltip
                            trigger="hover"
                            placement="top"
                            target="print"
                            className="align-print-tooltip"
                          >
                            {data.searchResultsEnJson.printTooltip}
                          </StyledTooltip>
                        </div>
                        <div xs={12} className="filter-desktop">
                          <Advanced
                            location={this.props.location}
                            pageContext={this.props.pageContext}
                            navigate={this.props.navigate}
                            searchCallBack={() => {
                              setTimeout(() => {
                                this.setState({ resetPageAndFilters: true });
                                this.refreshSearchParams(
                                  this.getSearchPayload()
                                );
                                this.makeSearchRequest(this.getSearchPayload());
                              });
                            }}
                            studyIds={this.state.studyIds}
                          />
                        </div>
                        <div>
                          {this.state.totalResults !== null &&
                          this.state.searchResults.length > 0 ? (
                            <div>
                              <Row>
                                <Col
                                  className="pagination-info"
                                  xs={this.isMobile ? 8 : 6}
                                >
                                  {this.state.searchResults &&
                                  this.state.searchResults.length
                                    ? `${this.state.searchParams.PageIndex *
                                        this.state.searchParams.PageSize +
                                        1}
                          - ${Math.min(
                            (this.state.searchParams.PageIndex + 1) *
                              this.state.searchParams.PageSize,
                            this.state.totalResults
                          )} of `
                                    : null}
                                  {this.state.totalResults}&nbsp;
                                  {data.searchResultsEnJson.results}
                                </Col>
                                {this.state.totalResults > 10 ? (
                                  <Col
                                    className={'pagination-container'}
                                    xs={this.isMobile ? 4 : 6}
                                  >
                                    <Pagination
                                      activePage={
                                        this.state.searchParams.PageIndex + 1
                                      }
                                      itemsCountPerPage={
                                        this.state.searchParams.PageSize
                                      }
                                      totalItemsCount={this.state.totalResults}
                                      onChange={this.goToPage}
                                      simple={this.isMobile}
                                    />
                                  </Col>
                                ) : null}
                              </Row>
                            </div>
                          ) : null}
                          {NProgress.status !== null ? (
                            <SpinnerWrapper>
                              <CustomSpinner />
                            </SpinnerWrapper>
                          ) : (
                            <ResultsTable>
                              {this.state.totalResults >= 1 &&
                              this.state.totalResults >
                                this.state.searchParams.PageIndex * 10 ? (
                                this.renderResultsTable(
                                  this.state.searchResults
                                )
                              ) : this.state.searchResults.length < 1 &&
                                this.state.totalResults !== null ? (
                                <div className={'no-results-message'}>
                                  {data.searchResultsEnJson.noResultsText}
                                  {`${this.buildDisplayFilters(
                                    this.getSearchPayload(),
                                    false
                                  )}. `}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        data.searchResultsEnJson.changeSelection
                                          .childMarkdownRemark.html,
                                    }}
                                  />
                                </div>
                              ) : null}
                            </ResultsTable>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </ResultsPage>
            </Fonts>
          </Layout>
          <div style={{ position: 'absolute', top: '0', zIndex: '9999' }}>
            <Collapse isOpen={this.state.showFilter}>
              <Advanced
                location={this.props.location}
                pageContext={this.props.pageContext}
                navigate={this.props.navigate}
                isMobile={this.isMobile}
                searchCallBack={() => {
                  this.setState({ resetPageAndFilters: true });
                  this.refreshSearchParams(this.getSearchPayload());
                  this.makeSearchRequest(this.getSearchPayload());
                }}
                closeFilter={this.showFilter}
              />
            </Collapse>
          </div>
        </div>
      </ConnectDataContext.Provider>
    );
  }
}

export default Results;

Results.propTypes = {
  data: PropTypes.object.isRequired,
  navigate: PropTypes.func,
  location: PropTypes.object,
  pageContext: PropTypes.object.isRequired,
};

export const query = graphql`
  query ResultsPage {
    searchResultsEnJson {
      title
      share
      results
      filter
      noAddressSpecified
      learnMore
      whereToJoin
      viewResults
      googleMapsMarkerTitle
      printTooltip
      noLimit
      tableHeader {
        status
        title
        conditions
        medicalCondition
        distance
        requirements
      }
      noResultsText
      changeSelection {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      noResultsParams {
        conditions
        Status
        phases
        gender
        ageRange
        studyResult
      }
      resultMap {
        title
        conditions
        studyId
        city
        state
        postalCode
        country
      }
      addThisWidgetParams {
        dataURL
        dataTitle
        dataDescription
      }
      seo {
        pageTitle
        pageDescriptionWithFilters
        pageDescriptionWithoutFilters
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
