import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../modal/modal';
import { FaTimes } from 'react-icons/fa';
import {
  TopHeader,
  MainContent,
  FiltersContainer,
  Actions,
} from './modal-conditions.css';
import CheckboxGroup from '../checkbox/checkbox-group';
import { Formik } from 'formik';
import { IoIosArrowForward } from 'react-icons/io';

const ModalConditions = ({
  conditions,
  isModalOpen,
  showModal,
  hideModal,
  selectedFilters,
  onSubmit,
}) => {
  const formInitialValue =
    selectedFilters.length > 0
      ? selectedFilters.map(val => val.InternalValue)
      : [];
  const [filterInput, setFilterInput] = useState('');
  const [filteredConditions, setFilteredConditions] = useState(conditions);

  const getConditionsCount = () => {
    let count = 0;
    conditions.forEach(val => {
      if (val.Children) {
        count += val.Children.length;
      }
    });
    return count;
  };

  const handleInputChange = e => {
    setFilterInput(e.target.value);
  };

  const handleFormSubmit = values => {
    onSubmit(values.conditions);
    hideModal();
    setTimeout(() => {
      setFilterInput('');
    }, 100);
  };

  useEffect(() => {
    if (filterInput !== '') {
      const filteredConditions = conditions
        .filter(condition => {
          let conditionFound = false;
          if (
            condition.Children.find(child =>
              child.DisplayValue.toLowerCase().includes(
                filterInput.toLowerCase()
              )
            )
          ) {
            conditionFound = true;
          }

          if (conditionFound) {
            return condition;
          }
        })
        .map(condition => {
          const filteredChildren = condition.Children.filter(child =>
            child.DisplayValue.toLowerCase().includes(filterInput.toLowerCase())
          );
          const filteredCondition = Object.assign({}, condition, {
            Children: filteredChildren,
          });
          return filteredCondition;
        });
      setFilteredConditions(filteredConditions);
    } else {
      setFilteredConditions(conditions);
    }
  }, [filterInput]);

  return (
    <Modal
      type="all-conditions"
      open={isModalOpen}
      showModal={showModal}
      hideModal={hideModal}
      buttonText={`See all conditions (${getConditionsCount()})`}
      arrowRight={false}
    >
      <div>
        <TopHeader>
          <h2>{'Condition Category'}</h2>
          <button className="close-icon" onClick={hideModal}>
            <FaTimes />
          </button>
        </TopHeader>
      </div>
      <div>
        <Formik
          initialValues={{
            conditions: formInitialValue,
          }}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            handleFormSubmit(values);
            actions.setSubmitting(false);
          }}
          render={({ values, setFieldValue, handleSubmit }) => {
            return (
              <>
                <MainContent>
                  <div className="input-wrapper">
                    <input
                      value={filterInput}
                      onChange={handleInputChange}
                      type="text"
                      placeholder="Search for Condition Category"
                    />
                    {filterInput.length > 0 ? (
                      <FaTimes
                        className="close-icon"
                        onClick={() => {
                          setFilterInput('');
                        }}
                      />
                    ) : null}
                  </div>
                  <FiltersContainer>
                    <CheckboxGroup
                      isTitleToggleable={false}
                      group={filteredConditions}
                      groupName={'conditions'}
                      isSimple={false}
                    />
                  </FiltersContainer>
                </MainContent>

                <Actions>
                  <button
                    className="clear-button"
                    onClick={() => {
                      setFieldValue('conditions', []);
                    }}
                    disabled={values.conditions.length === 0}
                  >
                    {'Clear Filters'}
                  </button>
                  <button
                    className={'apply-btn'}
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {'Apply Filters'}
                    <span>
                      <IoIosArrowForward />
                    </span>
                  </button>
                </Actions>
              </>
            );
          }}
        />
      </div>
    </Modal>
  );
};

ModalConditions.propTypes = {
  conditions: PropTypes.array.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedFilters: PropTypes.any,
};

export default ModalConditions;
