import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import {
  Row,
  Col,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { FaTimes, FaDownload, FaCheck } from 'react-icons/fa';
import { IoIosArrowDown, IoIosArrowBack } from 'react-icons/io';
import { PopupContainer } from './download.popup.css';
import { areAnalyticsCookiesEnabled } from '../../helpers/areAnalyticsCookiesEnabled';

class DownloadPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      availableDocs: [],
      selectedDocument: '',
      selectedLanguage: [],
      isDropdownOpen: null,
      downloadDoc: { Url: '', TypeInternal: '' },
      showThankMsg: false,
    };

    this.valueChange = this.valueChange.bind(this);
  }

  componentDidMount() {
    const { attachments } = this.props;
    const availableDocs = [];
    const selectedLanguage = [];

    attachments
      .sort((a, b) => (a.Language > b.Language ? 1 : -1))
      .forEach(doc => {
        const currentDocIndex = availableDocs.findIndex(
          elem => elem.documentName === doc.TypeInternal
        );
        currentDocIndex < 0
          ? availableDocs.push({
              documentName: doc.TypeInternal,
              availableLangs: [doc.Language],
            })
          : availableDocs[currentDocIndex].availableLangs.push(doc.Language);
      });

    availableDocs.forEach(doc => {
      selectedLanguage.push({
        documentName: doc.documentName,
        selectedLang:
          doc.availableLangs.length > 1 ? '' : doc.availableLangs[0],
      });
    });

    this.setState({
      availableDocs,
      selectedLanguage,
      isDropdownOpen: availableDocs.map(() => false),
    });
  }

  valueChange(event) {
    if (event && event.target) {
      this.setState({ selectedDocument: `${event.target.value}` });
    }

    const docToDownload = this.props.attachments.find(
      elem =>
        elem.TypeInternal === `${event.target.value}` &&
        elem.Language ===
          this.state.selectedLanguage.find(
            elem => elem.documentName === `${event.target.value}`
          ).selectedLang
    );
    this.setState({
      downloadDoc: {
        Url: docToDownload.Url,
        TypeInternal: docToDownload.TypeInternal,
      },
    });
  }

  trackLink(event, attachment) {
    if (!areAnalyticsCookiesEnabled()) {
      return;
    }

    this.props.trackAnalytics(attachment);
    if (event.target && typeof window !== 'undefined' && window.ga) {
      try {
        window.ga.getAll().forEach(_ga => {
          _ga.send('event', 'Downloads', 'Downloads', event.target.href);
        });
      } catch (ev) {
        console.log(ev);
      }
    }
  }

  dropDownOnClick = (doc, lang) => {
    const itemIndex = this.state.selectedLanguage.findIndex(
      elem => elem.documentName === doc
    );
    const selectedLanguage = this.state.selectedLanguage.map((item, index) => {
      if (index === itemIndex) {
        return { documentName: doc, selectedLang: lang };
      } else {
        return item;
      }
    });
    this.setState({ selectedLanguage });
  };

  toggleDropdown = index => {
    this.setState({
      isDropdownOpen: this.state.isDropdownOpen.map((item, idx) =>
        idx === index ? !item : item
      ),
    });
  };

  showThanks = bool => {
    this.setState({ showThankMsg: bool, selectedDocument: '' });
    if (bool) {
      const selectedLanguage = this.state.selectedLanguage.map(item => {
        return {
          documentName: item.documentName,
          selectedLang: item.selectedLang !== null ? '' : item.selectedLang,
        };
      });
      this.setState({ selectedLanguage });
    }
  };

  render() {
    const { hideModal, attachments, data } = this.props;
    return (
      <PopupContainer>
        <button className="close-button" onClick={hideModal}>
          <FaTimes />
        </button>
        {!this.state.showThankMsg ? (
          <div>
            <h3>{data.downloadPopupJson.title}</h3>
            <Row className="align-fields">
              <Col xs={7} md={5} lg={5}>
                <h4>{data.downloadPopupJson.availableDoc}</h4>
              </Col>
              <Col md={5}>
                <h4>{data.downloadPopupJson.availableLang}</h4>
              </Col>
              <Col xs={5} md={2}>
                <h4 className={'mobile-select'}>
                  {data.downloadPopupJson.select}
                </h4>
              </Col>
            </Row>
            {this.state.availableDocs.map((doc, index) => (
              <Row key={index} className="align-fields">
                <Col xs={9} sm={9} md={5} lg={5}>
                  <p>
                    {
                      attachments.find(
                        elem => elem.TypeInternal === doc.documentName
                      ).TypeDisplay
                    }
                  </p>
                </Col>
                <Col xs={3} md={2} className="radio-btn-container mobile">
                  <Label htmlFor={`${doc.documentName}_${index}_mobile`}>
                    <input
                      type="radio"
                      value={`${doc.documentName}`}
                      disabled={
                        doc.availableLangs.length > 1 &&
                        this.state.selectedLanguage.find(
                          elem => elem.documentName === doc.documentName
                        ).selectedLang === ''
                          ? true
                          : false
                      }
                      onChange={this.valueChange}
                      id={`${doc.documentName}_${index}_mobile`}
                      name="selectedDoc"
                    />
                    <span className="radiomark" />
                  </Label>
                </Col>
                <Col xs={12} md={5}>
                  <h4>{data.downloadPopupJson.availableLang}</h4>
                  {doc.availableLangs.length > 1 ? (
                    <Dropdown
                      isOpen={this.state.isDropdownOpen[index]}
                      toggle={() => this.toggleDropdown(index)}
                    >
                      <DropdownToggle
                        className={
                          this.state.isDropdownOpen[index]
                            ? 'select-btn active'
                            : 'select-btn'
                        }
                        tag="button"
                        type="button"
                        name="language-selector"
                      >
                        {this.state.isDropdownOpen[index]
                          ? data.downloadPopupJson.selectFileLang
                          : this.state.selectedLanguage.find(
                              elem => elem.documentName === doc.documentName
                            ).selectedLang
                          ? this.state.selectedLanguage.find(
                              elem => elem.documentName === doc.documentName
                            ).selectedLang
                          : data.downloadPopupJson.multipleLangAvailable}
                        <span>
                          <IoIosArrowDown />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu flip={false} direction="down">
                        {doc.availableLangs.map((lang, langIndex) => (
                          <DropdownItem
                            onClick={() => {
                              this.dropDownOnClick(doc.documentName, lang);
                            }}
                            key={langIndex}
                            value={lang}
                          >
                            {lang}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  ) : (
                    <p>
                      {doc.availableLangs[0] === null ||
                      doc.availableLangs[0] === ''
                        ? data.downloadPopupJson.english
                        : doc.availableLangs[0]}
                    </p>
                  )}
                </Col>
                <Col xs={3} md={2} className="radio-btn-container">
                  <Label htmlFor={`${doc.documentName}_${index}`}>
                    <input
                      type="radio"
                      value={`${doc.documentName}`}
                      disabled={
                        doc.availableLangs.length > 1 &&
                        this.state.selectedLanguage.find(
                          elem => elem.documentName === doc.documentName
                        ).selectedLang === ''
                          ? true
                          : false
                      }
                      onChange={this.valueChange}
                      id={`${doc.documentName}_${index}`}
                      name="selectedDoc"
                    />
                    <span className="radiomark" />
                  </Label>
                </Col>
              </Row>
            ))}
            <div className="download-btn">
              <a
                href={`${this.state.downloadDoc.Url}`}
                onClick={e => {
                  this.showThanks(true);
                  this.trackLink(e, this.state.downloadDoc.TypeInternal);
                }}
                disabled={this.state.selectedDocument === ''}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.downloadPopupJson.download}
                <span>
                  <FaDownload />
                </span>
              </a>
            </div>
          </div>
        ) : (
          <div className="thanks-container">
            <div>
              <FaCheck />
            </div>
            <h4>{data.downloadPopupJson.downloadStarted}</h4>
            <p>{data.downloadPopupJson.meanwhileText}</p>
            <button onClick={() => this.showThanks(false)}>
              {data.downloadPopupJson.downloadMore}
              <span>
                <IoIosArrowBack />
              </span>
            </button>
          </div>
        )}
      </PopupContainer>
    );
  }
}

DownloadPopup.propTypes = {
  hideModal: PropTypes.func,
  attachments: PropTypes.array,
  trackAnalytics: PropTypes.func,
  data: PropTypes.object,
};

const DownloadPopupWithQuery = props => (
  <StaticQuery
    query={graphql`
      query DownloadPopupQuery {
        downloadPopupJson {
          title
          availableDoc
          availableLang
          select
          selectFileLang
          multipleLangAvailable
          english
          download
          downloadStarted
          meanwhileText
          downloadMore
        }
      }
    `}
    render={data => <DownloadPopup data={data} {...props} />}
  />
);

export default DownloadPopupWithQuery;
