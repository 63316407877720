import React, { useState, useContext } from 'react';
export const ConnectDataContext = React.createContext(null);

const useConnectInfo = ([...ids]) => {
  const { connectApiToken, connectApiUrl, info, setInfo } = useContext(
    Object.assign(ConnectDataContext)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestDone, setIsRequestDone] = useState(false);

  const alreadyHasData = () => {
    let hasData = true;
    ids.forEach(id => {
      if (!Object.keys(info).includes(id)) {
        hasData = false;
      }
    });
    return hasData;
  };

  const loadInfo = async () => {
    // info loaded already
    if ((Object.keys(info).length && alreadyHasData()) || isRequestDone) {
      return;
    }

    setIsLoading(true);
    const parsedIds = ids.map(id => `nct_ids[]=${encodeURIComponent(id)}`);
    try {
      const response = await fetch(
        `${connectApiUrl}/trials?${parsedIds.join('&')}`,
        {
          headers: {
            authorization: `Bearer ${connectApiToken}`,
            accept: 'application/json, text/plain, */*',
            'accept-language': 'en-US,en;q=0.9',
            'content-type': 'application/json;charset=UTF-8',
          },
          method: 'GET',
          mode: 'cors',
        }
      );

      const body = await response.json();
      setIsRequestDone(true);
      setInfo(body.trials);
    } catch (e) {
      console.log('useConnectInfo::loadInfo::error', e);
      // throw new Error(e.message || 'Something went wrogn!');
    }
    setIsLoading(false);
  };

  return React.useMemo(
    () => ({
      isLoading,
      loadInfo,
      getInfo: id => {
        return info[id] || null;
      },
      // eslint-disable-next-line
    }),
    [isLoading, connectApiToken, connectApiUrl, info]
  );
};

export default useConnectInfo;
