import React, { useState } from 'react';
import { Tr, Td } from 'react-super-responsive-table';
import PropTypes from 'prop-types';
import { FaRegPlusSquare, FaRegMinusSquare } from 'react-icons/fa';
import ClampLines from 'react-clamp-lines';
import { IoIosArrowForward } from 'react-icons/io';
import { Link } from 'gatsby';
import { trackAnalytics } from '../../helpers/trackAnalytics';
import Modal from '../modal/modal';
import DownloadPopup from '../download-popup';
import useConnectInfo from '../../helpers/useConnectInfo';

const TableRow = ({ data, row, index, searchParams }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getInfo } = useConnectInfo([row.NCTID]);
  const studyConnectInfo = getInfo(row.NCTID);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const hideModal = () => {
    setIsModalOpen(false);
  };
  const filteringCondition = conditions => {
    let uniqCondition = [];
    return conditions.filter(cond => {
      if (!uniqCondition.includes(cond.DisplayValue)) {
        uniqCondition.push(cond.DisplayValue);
        return true;
      }
      return false;
    });
  };
  const filteringRownCondition = conditions => {
    let uniqCondition = [];
    return conditions.filter(cond => {
      if (!uniqCondition.includes(cond.LookupValue)) {
        uniqCondition.push(cond.LookupValue);
        return true;
      }
      return false;
    });
  };
  const generateSearchParamForStudyDetails = (row, isRecruiting) => {
    const studyDetailsPath = `${row.UniqueIdentifier}${
      searchParams.Latitude.length && searchParams.Latitude.length
        ? `/?searchLat=${row.Location_Latitude}&searchLong=${
            row.Location_Longitude
          }&name=${
            row.Location_Name !== null ? row.Location_Name : ''
          }&baseLat=${searchParams.Latitude}&baseLong=${searchParams.Longitude}`
        : ''
    }`;

    return isRecruiting
      ? studyDetailsPath.concat('#where-to-participate')
      : studyDetailsPath;
  };

  return (
    <>
      <Tr
        key={'result_' + row.Id}
        className={'result-row ' + (index % 2 === 1 ? 'odd' : 'even')}
      >
        <Td className={'condition-section'}>
          {row.Conditions
            ? filteringCondition(row.Conditions)
                .map(cond =>
                  cond['DisplayValue:OneLinkTx']
                    ? cond['DisplayValue:OneLinkTx']
                    : cond.DisplayValue
                )
                .join(', ')
            : undefined}
        </Td>
        <Td className={'medical-condition-section'}>
          {row.Conditions
            ? filteringRownCondition(row.Conditions)
                .map(cond => cond.LookupValue)
                .join(', ')
            : undefined}
        </Td>
        <Td className={'status-section'}>
          <div
            className={
              'status-icon ' + `${row.StatusInternal}`.toLocaleLowerCase()
            }
          />
          {row.StatusDisplay}
        </Td>
        <Td className={'requirements-section'}>
          <div
            className={
              'requirements-icon ' + `${row.GenderInternal}`.toLocaleLowerCase()
            }
          />
          {row.MinAge && row.MaxAge ? (
            `${row.MinAge}-${row.MaxAge}`
          ) : row.MinAge ? (
            `${row.MinAge}+`
          ) : row.MaxAge ? (
            `0-${row.MaxAge}`
          ) : (
            <p>{data.searchResultsEnJson.noLimit}</p>
          )}
        </Td>
        <Td
          className={
            'distance-section' +
            (row.Location_City !== null && row.Location_CountryDisplay !== null
              ? ' location-text'
              : '')
          }
        >
          {row.Location_CountryDisplay !== null
            ? row.Location_City !== null
              ? `${row.Location_City}\n${row.Location_CountryDisplay}`
              : `\n${row.Location_CountryDisplay}`
            : data.searchResultsEnJson.noAddressSpecified}
          {row.IsFlexibleTrial ? (
            <>
              <br />
              <br />
              <div className="d-flex align-items-center">
                <img
                  src={'/images/dct_logo.png'}
                  alt="DCT Icon"
                  className="mr-3"
                  height="32px"
                  width="32px"
                />
                <span> {'Potential for remote participation (limited)'} </span>
              </div>
            </>
          ) : null}
        </Td>
        <Td className={'details-section'}>
          <ClampLines
            text={`${row.UniqueIdentifier}-${
              row['Title:OneLinkTx'] ? row['Title:OneLinkTx'] : row.Title
            }`}
            id="text-mobile"
            lines={1}
            innerElement="div"
            ellipsis="..."
            moreText={<FaRegPlusSquare />}
            lessText={<FaRegMinusSquare />}
          />
          <div className="align-buttons">
            <Link
              className={'links learn-more'}
              onClick={() => {
                trackAnalytics('TrialDetailsClick', {
                  TrialId: row.UniqueIdentifier,
                  PositionInList: index,
                  TrialTitle: row.Title,
                  TrialStatus: row.StatusInternal,
                  Conditions: row.Conditions
                    ? row.Conditions.map(condition => condition.InternalValue)
                    : [],
                });
              }}
              to={`/study/${generateSearchParamForStudyDetails(row)}/`}
            >
              {data.searchResultsEnJson.learnMore}
              <span>
                <IoIosArrowForward />
              </span>
            </Link>
            {studyConnectInfo && studyConnectInfo.status === 'active' ? (
              <a
                href={`${studyConnectInfo.summary_url}/prescreener`}
                target="_blank"
                rel="noopener noreferrer"
                className={'ask-to-join-modal-btn'}
              >
                {'See if you pre-qualify'}
                <span>
                  <IoIosArrowForward />
                </span>
              </a>
            ) : row.StatusInternal === 'RECSTATUS_RECRUITING' ||
              row.StatusInternal === 'RECSTATUS_NOT_YET_RECRUITING' ? (
              <Link
                className={'ask-to-join-modal-btn'}
                to={`/study/${generateSearchParamForStudyDetails(row, true)}`}
              >
                {data.searchResultsEnJson.whereToJoin}
                <span>
                  <IoIosArrowForward />
                </span>
              </Link>
            ) : row.Attachments !== null &&
              Array.isArray(row.Attachments) &&
              row.Attachments.length > 0 ? (
              <Modal
                open={isModalOpen}
                type="view-results"
                showModal={showModal}
                hideModal={hideModal}
                buttonText={data.searchResultsEnJson.viewResults}
                arrowRight={true}
              >
                <DownloadPopup
                  attachments={row.Attachments}
                  hideModal={hideModal}
                  trackAnalytics={attachment => {
                    trackAnalytics('DocumentDownload', {
                      attachment,
                      TrialId: row.UniqueIdentifier,
                      TrialTitle: row['Title:OneLinkTx']
                        ? row['Title:OneLinkTx']
                        : row.Title,
                      TrialStatus: row.StatusDisplay,
                      Conditions: row.Conditions
                        ? row.Conditions.map(cond => cond.InternalValue)
                        : [],
                    });
                  }}
                />
              </Modal>
            ) : null}
          </div>
        </Td>
      </Tr>
      <Tr
        key={'result_second_row' + row.Id}
        className={'result-second-row ' + (index % 2 === 1 ? 'odd' : 'even')}
      >
        <Td colSpan="6">
          <div
            data-content={`${row.UniqueIdentifier}-${
              row['Title:OneLinkTx'] ? row['Title:OneLinkTx'] : row.Title
            }`}
          >
            <ClampLines
              text={`${row.UniqueIdentifier}-${
                row['Title:OneLinkTx'] ? row['Title:OneLinkTx'] : row.Title
              }`}
              id="text"
              lines={1}
              innerElement="div"
              ellipsis="..."
              moreText={<FaRegPlusSquare />}
              lessText={<FaRegMinusSquare />}
            />
          </div>
        </Td>
      </Tr>
    </>
  );
};

TableRow.propTypes = {
  data: PropTypes.any.isRequired,
  searchParams: PropTypes.any.isRequired,
  row: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default TableRow;
